import React, { useState } from "react";
import {
  Box,
  Button,
  TextInput,
  Heading,
  Layer,
  Text,
  Select,
  CheckBox,
} from "grommet";
import { Edit } from "grommet-icons";
import { useFetchHotel, useFetchRoomTypes } from "./redux/hooks";

export default function EditDoor(props) {
  const { type, onSubmit, doorToEdit, disabled, allDoors } = props;
  const { roomTypes } = useFetchRoomTypes();
  const { hotel } = useFetchHotel();
  const [door, setDoor] = useState();
  const [open, setOpen] = useState();
  const [restrictedToDoors, setRestrictedToDoors] = useState();

  const onOpen = () => {
    if (doorToEdit) {
      setDoor(doorToEdit);
      const nextRestrictedToDoors = (doorToEdit.restrictedToDoorIds || [])
        .map((id) => allDoors.find((d) => `${d._id}` === id)?.name)
        .join(",");
      setRestrictedToDoors(nextRestrictedToDoors);
      setOpen(true);
    }
  };
  const onClose = () => setOpen(undefined);

  function handleInputChange({ target: { name, value } }) {
    setDoor({ ...door, [name]: value });
  }
  function handleCheckboxChange({ target: { name, checked } }) {
    setDoor({ ...door, [name]: checked });
  }
  function handleRestrictedToDoorsChange({ target: { name, value } }) {
    setRestrictedToDoors(value);
    const restrictedToDoorIds = (value || "")
      .split(",")
      .map((name) => allDoors.find((d) => d.name === name.trim())?._id);
    setDoor({ ...door, restrictedToDoorIds });
  }
  const handleEdit = () => {
    onClose();
    onSubmit({
      ...door,
      ...(door.type === "room" && { roomType: door.roomType._id }),
      ...(hotel.lockType === "oracode" && door.lockName?.length > 0
        ? { lockId: 1 }
        : { lockId: undefined }),
    });
  };

  return (
    <div>
      <Button
        primary
        icon={<Edit size="small" />}
        size="small"
        color="neutral-3"
        onClick={onOpen}
        disabled={disabled}
      />
      {open && (
        <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
          <Box pad="medium" gap="small" width="medium">
            <Heading level={3} margin="none">
              Edit {type}
            </Heading>
            <Text>Name</Text>
            <TextInput
              name="name"
              value={door.name}
              onChange={handleInputChange}
            />
            {hotel.lockType === "oracode" && (
              <>
                <Text>Oracode Door ID</Text>
                <TextInput
                  name="lockName"
                  value={door.lockName || ""}
                  onChange={handleInputChange}
                />
              </>
            )}
            <Text>Pms Id</Text>
            <TextInput
              name="pmsId"
              value={door.pmsId || ""}
              onChange={handleInputChange}
            />
            {type === "room" && (
              <>
                <Text>Room type</Text>
                <Select
                  name="roomType"
                  placeholder="Select the type of room"
                  value={door.roomType._id}
                  labelKey="name"
                  valueKey={{ key: "_id", reduce: true }}
                  options={roomTypes}
                  onChange={({ value }) =>
                    setDoor({
                      ...door,
                      roomType: roomTypes.find((x) => x._id === value),
                    })
                  }
                />
                <Box margin={{ top: "small" }}>
                  <CheckBox
                    name="validNoLock"
                    checked={door.validNoLock}
                    onChange={handleCheckboxChange}
                    label="Valid without lock"
                  />
                </Box>
              </>
            )}
            {type === "area" && (
              <>
                {" "}
                <Text>Restricted to doors</Text>
                <TextInput
                  name="restrictedToDoorIds"
                  value={restrictedToDoors || ""}
                  onChange={handleRestrictedToDoorsChange}
                />
              </>
            )}
            <Box
              as="footer"
              gap="small"
              direction="row"
              align="center"
              justify="end"
              pad={{ top: "medium", bottom: "small" }}
            >
              <Button label="Cancel" onClick={onClose} color="dark-3" />
              <Button
                label="Edit"
                onClick={handleEdit}
                primary
                color="status-ok"
              />
            </Box>
          </Box>
        </Layer>
      )}
    </div>
  );
}
