import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import {
  Box,
  Header,
  Heading,
  DataTable,
  Button,
  CheckBox,
  Meter,
  Text,
} from "grommet";
import { Trash, Trigger, Lock, Action, Validate } from "grommet-icons";
import { onDatatableSearch } from "@common/utils";
import {
  presentToastSuccess,
  presentToastErrorContent,
  presentToastWarning,
} from "@shared/Toast";
import { AddDoor } from "./";
import DoorService from "../../services/door.service";
import { presentAlertPrompt } from "@shared/Alert";
import { useFetchHotel, useSetCurrentUser } from "./redux/hooks";
import SecondaryDoorIcon from "@shared/SecondaryDoorIcon";
import RestrictedAreaIcon from "@shared/RestrictedAreaIcon";
import EditDoor from "./EditDoor";

export default function SettingsDoorTable(props) {
  const { title, type, doors, onRefresh } = props;
  const [checked, setChecked] = useState([]);
  const [list, setList] = useState([]);
  const [sort, setSort] = useState({ property: "name", direction: "asc" });
  const { hotel } = useFetchHotel();
  const { isLoxeAdmin } = useSetCurrentUser();

  useEffect(() => {
    setList((doors || []).filter((x) => x.type === type));
  }, [doors, type]);

  const onCheck = (event, value) => {
    if (event.target.checked) {
      setChecked([...checked, value]);
    } else {
      setChecked(checked.filter((item) => item !== value));
    }
  };

  const onCheckAll = ({ target: { checked } }) => {
    if (list.length) setChecked(checked ? list.map((datum) => datum._id) : []);
  };

  const columns = [
    {
      property: "checkbox",
      render: (datum) => (
        <CheckBox
          key={datum._id}
          checked={checked.indexOf(datum._id) !== -1}
          onChange={(e) => onCheck(e, datum._id)}
        />
      ),
      header: (
        <CheckBox
          checked={checked.length === list.length}
          indeterminate={checked.length > 0 && checked.length < list.length}
          onChange={onCheckAll}
        />
      ),
      sortable: false,
    },
    {
      property: "name",
      header: "Name",
      primary: true,
      search: true,
      sortable: true,
      render: (datum) =>
        !datum.disabled ? (
          <Box direction="row" gap="small">
            <Text>{datum.name}</Text>
            {isLoxeAdmin && (
              <Text size="11px" color="grey" alignSelf="center">
                {datum.pmsId}
              </Text>
            )}
            {datum.isSecondary && (
              <SecondaryDoorIcon
                pkey={`secondary-${datum._id}`}
                mainRoomName={
                  list.find((x) => x.secondaryDoor === datum._id).name
                }
              />
            )}
            {(datum.restrictedToDoorIds || []).length > 0 && (
              <RestrictedAreaIcon
                pkey={`restricted-${datum._id}`}
                restrictedRoomNames={doors
                  ?.map((x) =>
                    datum.restrictedToDoorIds.includes(x._id) ? x.name : null
                  )
                  .filter(Boolean)}
              />
            )}
          </Box>
        ) : (
          <Text
            weight="bold"
            color="status-error"
            style={{
              textDecorationLine: "line-through",
              textDecorationStyle: "solid",
            }}
          >
            {datum.name}
          </Text>
        ),
    },
  ];
  if (type === "room")
    columns.push({
      property: "roomType",
      header: "Type",
      search: true,
      sortable: true,
      render: (datum) =>
        !!datum.roomType ? (
          <Text truncate size="small">
            {datum.roomType.name}
          </Text>
        ) : (
          "-"
        ),
    });

  columns.push({
    property: "lockBattery",
    header: hotel.lockType === "oracode" ? "Oracode Name" : "Mobile Key",
    render: (datum) =>
      datum.validNoLock ? (
        <Box align="center">
          <Validate size="small" />
        </Box>
      ) : datum.lockId ? (
        hotel.lockType === "oracode" ? (
          <Text size="small">{datum.lockName}</Text>
        ) : hotel.disableBatteryCheck ? (
          <Box align="center">
            <Lock size="small" />
          </Box>
        ) : (
          <Box align="center" direction="row">
            <Trigger size="small" />
            <Box width="20px" margin={{ horizontal: "5px" }}>
              <Meter
                values={[
                  {
                    value: datum.lockBattery,
                    color:
                      datum.lockBattery >= 70
                        ? "status-ok"
                        : datum.lockBattery >= 20
                        ? "status-warning"
                        : "status-error",
                  },
                ]}
                thickness="small"
                size="small"
                background="light-6"
              />
            </Box>
            <Text size="small">{Math.round(datum.lockBattery * 10) / 10}%</Text>
          </Box>
        )
      ) : (
        "-"
      ),
    align: "center",
    sortable: true,
  });

  const handleAddDoor = (pDoors) => {
    DoorService.addDoors(pDoors).then(
      (response) => {
        onRefresh();
        presentToastSuccess("Success");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const handleRemoveDoor = () => {
    presentAlertPrompt({
      title: "Are you sure?",
      message:
        "If there is a BLE Module in a removed door, you will have to reset it. This won't delete currently used doors.",
      onOK: removeDoors,
      buttonOKText: "Delete",
      buttonOKColor: "status-critical",
    });
  };

  const removeDoors = () => {
    DoorService.deleteDoors(checked).then(
      (response) => {
        onRefresh();
        setChecked([]);
        if (response.data.doorsInUse.length > 0)
          presentToastWarning(
            "One or more items couldn't be removed because they are in use."
          );
        else presentToastSuccess("Success");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const handleToggleDoor = () => {
    presentAlertPrompt({
      title: "Are you sure?",
      message:
        "This will only affect doors that has a lock. A disabled lock means " +
        "that no guests in this room will receive an online check-in invitation, " +
        "and no key for this room will be sent automatically.",
      onOK: toggleDoors,
      buttonOKText: "Disable/Enable",
      buttonOKColor: "status-warning",
    });
  };

  const toggleDoors = () => {
    DoorService.toggleDoors(checked).then(
      (response) => {
        onRefresh();
        setChecked([]);
        presentToastSuccess("Success");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const handleEditDoor = (door) => {
    DoorService.update(door).then(
      (response) => {
        onRefresh();
        setChecked([]);
        presentToastSuccess("Success");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  return (
    <Box>
      <Header
        align="center"
        direction="row"
        justify="between"
        gap="medium"
        fill="horizontal"
      >
        <Heading level="4">{title}</Heading>
        <AddDoor type={type} onSubmit={handleAddDoor} />
        {isLoxeAdmin && (
          <EditDoor
            type={type}
            onSubmit={handleEditDoor}
            doorToEdit={doors.find((x) => x._id === checked?.[0])}
            disabled={!checked.length}
            allDoors={doors}
          />
        )}
        <Button
          icon={<Action size="small" />}
          size="small"
          label="Enable/Disable"
          primary
          color="status-warning"
          onClick={handleToggleDoor}
          disabled={!checked.length}
        />
        <Button
          icon={<Trash size="small" />}
          size="small"
          label="Remove"
          primary
          color="status-critical"
          onClick={handleRemoveDoor}
          disabled={!checked.length}
        />
      </Header>
      <Box align="center" fill="horizontal">
        <DataTable
          columns={columns}
          data={list}
          primaryKey="id"
          sort={sort}
          onSort={setSort}
          onSearch={(search) =>
            onDatatableSearch(
              search,
              (doors || []).filter((x) => x.type === type),
              setList
            )
          }
        />
      </Box>
    </Box>
  );
}

SettingsDoorTable.propTypes = {};
SettingsDoorTable.defaultProps = {};
