import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  ResponsiveContext,
  Grid,
  Header,
  Box,
  Heading,
  Button,
  FormField,
  TextInput,
  Text,
  CheckBox,
  Image,
  Tabs,
  Tab,
  Tip,
} from "grommet";
import { FormNextLink, StatusWarning } from "grommet-icons";
import moment from "moment-timezone";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import {
  GuestService,
  CheckinService,
  ChargeService,
  NotificationService,
} from "@services";
import {
  BookingCalendarInput,
  BookingStatus,
  SecuredImage,
  HourInput,
} from "@shared";
import { RoomSelect } from "@dashboard";
import {
  VerifyCheckInModal,
  Conversation,
  EventList,
  ViewMenuRequestModal,
  KeysSentReportModal,
  AccessList,
} from "./components";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import { presentLoading, dismissLoading } from "@shared/Loading";
import { useFetchDoors, useFetchHotel } from "@dashboard/redux/hooks";
import { presentAlertPrompt } from "@shared/Alert";
import placeholderGuest from "@images/placeholder-guest.png";

import "./GuestDetails.scss";
import { ButtonWithConfirm } from "@features/shared";

const defaultValues = {
  _id: 0,
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  checkinDate: moment().hour(15),
  checkoutDate: moment().add(1, "days").hour(12),
  checkinTime: "15:00",
  checkoutTime: "12:00",
  roomNumber: "",
  roomType: 0,
  passcodes: [],
  currentDoors: [],
  invitationSent: false,
  status: {},
  onlineCheckin: {},
  magicLink: "",
  linkUsed: false,
  charges: [],
  messages: [],
  passcodeSent: null,
  hasChangedDateTimes: false,
};

const rows = {
  small: ["auto", "auto", "auto", "auto", "auto"],
  medium: ["auto", "auto", "auto"],
};

const tabMap = {
  info: 0,
  messages: 1,
  events: 2,
};

export default function GuestDetails(props) {
  var contextSize = useContext(ResponsiveContext);
  if (contextSize !== "small") contextSize = "medium";

  var { guestId: bookingDBId, tab } = useParams();
  const { hotel, fetchHotel } = useFetchHotel();
  const { doors } = useFetchDoors();
  const [formValues, setFormValues] = useState(defaultValues);
  const [accessDoors, setAccessDoors] = useState([]);
  const [booking, setBooking] = useState({});
  const [keysSentReport, setKeysSentReport] = useState(null);

  const [tabIndex, setTabIndex] = useState(tabMap[tab] || 0);
  const onActive = (nextIndex) => setTabIndex(nextIndex);
  const isOracode = hotel?.lockType === "oracode";

  const gridAreas = useMemo(
    () => ({
      small: [
        ["info", "info"],
        ["status", "status"],
        ["upsell", "upsell"],
        ["keys", "keys"],
        ...(hotel?.usePasscodes ? [["passcode", "passcode"]] : []),
        ["ekeys", "ekeys"],
        ...(!isOracode ? [["magicLink", "magicLink"]] : []),
      ],
      medium: [
        ["info", "status"],
        ["upsell", "keys"],
        ...(hotel?.usePasscodes ? [["passcode", "passcode"]] : []),
        ["ekeys", "ekeys"],
        ...(!isOracode ? [["magicLink", "magicLink"]] : []),
      ],
    }),
    [hotel?.usePasscodes, isOracode]
  );

  useEffect(() => {
    if (doors?.length && booking?.roomNumber) {
      const allDoors = [
        ...doors.filter((x) => x.lockId && !x.disabled && x.type === "area"),
        ...doors
          .filter(
            (x) =>
              (x.lockId || x.validNoLock) && !x.disabled && x.type === "room"
          )
          .sort((a, b) => (a.name < b.name ? -1 : 1)),
      ];

      const suggestedRoomObj = allDoors.find(
        (x) => x.name === booking.roomNumber
      );
      const suggestedSelected = allDoors.filter(
        (door) =>
          door.name === booking.roomNumber ||
          (door.type === "area" &&
            ((door.restrictedToDoorIds || []).length === 0 ||
              door.restrictedToDoorIds.includes(suggestedRoomObj?._id)))
      );
      setAccessDoors(suggestedSelected);
    }
  }, [doors, booking.roomNumber]);

  const refreshDetails = useCallback((id = bookingDBId) => {
    presentLoading();
    GuestService.getBooking(id).then(
      (response) => {
        dismissLoading();
        const booking = response.data;
        setBooking(booking);
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );

    //setTimeout(() => PmsService.updateDoorStatus(), 1000);
  }, []);

  useEffect(() => {
    if (booking?._id) {
      setFormValues({
        ...booking,
        currentDoors: [],
        passcodes: booking.passcodes || [],
        checkinDate: moment.tz(booking.checkinDate, hotel.timezone),
        checkoutDate: moment.tz(booking.checkoutDate, hotel.timezone),
        checkinTime: moment
          .tz(booking.checkinDate, hotel.timezone)
          .format("HH:mm"),
        checkoutTime: moment
          .tz(booking.checkoutDate, hotel.timezone)
          .format("HH:mm"),
        guestId: booking.guest._id,
        hasChangedDateTimes: false,
      });
    }
  }, [booking, hotel.timezone]);

  useEffect(() => {
    if (tabIndex === 1) {
      NotificationService.removeMessage(bookingDBId).then((response) => {
        fetchHotel();
      });
    }
  }, [bookingDBId, fetchHotel, tabIndex]);

  useEffect(() => {
    if (bookingDBId !== 0) {
      refreshDetails(bookingDBId);
    }
  }, [bookingDBId, hotel.timezone, refreshDetails]);

  function handleInputChange({ target: { name, value } }) {
    if (disabled()) return;
    var values = { ...formValues };
    values[name] = value;
    setFormValues(values);
  }

  function handleRoomInputChange({ name, roomType }) {
    var values = { ...formValues, roomNumber: name };
    if (roomType) values.roomType = roomType;
    setFormValues(values);
  }

  function handleCheckboxChange({ target: { name, checked } }) {
    if (disabled()) return;
    var values = { ...formValues };
    values[name] = checked;
    setFormValues(values);
  }

  function handleBookingDatesChange(checkinDate, checkoutDate) {
    var checkinDateString = moment(checkinDate).format("YYYY-MM-DD");
    var checkoutDateString = moment(checkoutDate).format("YYYY-MM-DD");
    checkinDate = moment
      .tz(checkinDateString + " " + formValues.checkinTime, hotel.timezone)
      .utc();
    checkoutDate = moment
      .tz(checkoutDateString + " " + formValues.checkoutTime, hotel.timezone)
      .utc();
    var values = { ...formValues, checkinDate, checkoutDate };
    setFormValues(values);

    setFormValues({ ...values, hasChangedDateTimes: true });
  }

  function handleHourInputChange({ name, value }) {
    var values = { ...formValues };
    values[name] = value;
    var arr = value.split(":");
    var hour = parseInt(arr[0]);
    var minute = parseInt(arr[1]);
    if (name === "checkinTime") {
      values.checkinDate = moment.utc(
        moment
          .tz(formValues.checkinDate, hotel.timezone)
          .hour(hour)
          .minute(minute)
      );
    } else if (name === "checkoutTime") {
      values.checkoutDate = moment.utc(
        moment
          .tz(formValues.checkoutDate, hotel.timezone)
          .hour(hour)
          .minute(minute)
      );
    }

    setFormValues({ ...values, hasChangedDateTimes: true });
  }

  const handleUpdate = async (action, restartCheckin) => {
    if (formValues._id !== 0) {
      var request = { ...formValues };
      if (action === "info") {
        const {
          _id,
          firstname,
          lastname,
          email,
          phone,
          checkinDate,
          checkoutDate,
          roomType,
          guestId,
          roomNumber,
        } = formValues;
        request = {
          _id,
          firstname,
          lastname,
          email,
          phone,
          checkinDate,
          checkoutDate,
          roomType,
          guestId,
          roomNumber,
        };
        if (
          action === "info" &&
          (formValues.currentDoors?.length > 0 ||
            formValues.passcodes.length > 0) &&
          formValues.hasChangedDateTimes
        ) {
          presentAlertPrompt({
            title: "Updating keys",
            message:
              "This will update the keys and passcodes with the new check-in and check-out times. We recommend that you update this information from your PMS, if it's possible. Do you still want to proceed?",
            onOK: () => doUpdateBooking(request, true),
            buttonOKText: "Yes, update",
            buttonOKColor: "brand",
          });
        } else {
          await doUpdateBooking(request, false);
        }
      } else if (action === "upsell") {
        const {
          _id,
          requestPhotoID,
          requestCardImprint,
          offerEarlyCheckin,
          offerUpgrade,
          offerLateCheckout,
        } = formValues;
        request = {
          _id,
          requestPhotoID,
          requestCardImprint,
          offerEarlyCheckin,
          offerUpgrade,
          offerLateCheckout,
          restartCheckin,
        };
        await doUpdateBooking(request);
      }
    }
  };

  const doUpdateBooking = async (request, sendKeys) => {
    try {
      const response = await GuestService.updateBooking(request);
      if (response.data.ok) {
        if (typeof formValues.requestPhotoID !== "boolean") {
          setFormValues({
            ...formValues,
            checkinDate: formValues.checkinDate,
            checkoutDate: formValues.checkoutDate,
            requestPhotoID: true,
            requestCardImprint: true,
            offerEarlyCheckin: true,
            offerUpgrade: true,
            offerLateCheckout: true,
          });
        }

        if (response.data.nModified || response.data.guestModified) {
          presentToastSuccess("Guest details updated");
          if (sendKeys) {
            handleSendKeys("update");
          } else {
            refreshDetails(bookingDBId);
          }
        } else {
          presentToastSuccess("No changes detected");
        }
      }
    } catch (error) {
      presentToastErrorContent(error);
    }
  };

  const handleSendKeys = (from) => {
    setKeysSentReport({ loading: true });
    GuestService.sendKeys(
      formValues._id,
      formValues.roomNumber,
      accessDoors,
      formValues.checkinDate,
      formValues.checkoutDate,
      from
    ).then(
      (response) => {
        setKeysSentReport(response.data);
        presentToastSuccess("Door access updated");
        refreshDetails(bookingDBId);
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
        setKeysSentReport({ error: error.response.data.message });
      }
    );
  };

  const deleteAllKeys = () => {
    setKeysSentReport({ loading: true });
    GuestService.sendKeys(
      formValues._id,
      formValues.roomNumber,
      [],
      formValues.checkinDate,
      formValues.checkoutDate
    ).then(
      (response) => {
        setKeysSentReport(response.data);
        presentToastSuccess("All doors access removed");
        refreshDetails(bookingDBId);
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
        setKeysSentReport({ error: error.response.data.message });
      }
    );
  };

  const closeKeysSentReport = () => {
    setKeysSentReport(null);
  };

  const handleCheckout = () => {
    presentAlertPrompt({
      title: "Are you sure?",
      message:
        "This will update the check-out date and revoke the mobile key. The guest will be warned by text/email/notification.",
      onOK: checkoutGuest,
      buttonOKText: "Check out",
      buttonOKColor: "status-critical",
    });
  };

  const checkoutGuest = () => {
    presentLoading();
    GuestService.checkout(bookingDBId).then(
      (response) => {
        dismissLoading();
        presentToastSuccess("The guest has successfully checked out");
        refreshDetails(bookingDBId);
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );
  };

  const handleSubmitVerification = (approved) => {
    presentLoading();
    CheckinService.approve(formValues._id, approved).then(
      (response) => {
        dismissLoading();
        presentToastSuccess("Status updated");
        refreshDetails(bookingDBId);
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );
  };

  const disabled = () => {
    return (
      formValues.status.slug === "checkedout" ||
      formValues.status.slug === "noshow" ||
      formValues.status.slug === "cancelled"
    );
  };

  const handleBack = () => {
    var index = window.location.href.lastIndexOf("/");
    window.location.href = window.location.href.substring(0, index);
  };

  const renderCharge = (charge) => {
    var label = "";
    var color = "";
    switch (charge.status) {
      case "confirmed":
        color = "status-ok";
        label = "Confirmed";
        break;
      case "cancelled":
        color = "status-error";
        label = "Cancelled";
        break;
      case "requested":
        color = "status-warning";
        label = "Requested";
        break;
      case "delivered":
        color = "neutral-3";
        label = "Delivered";
        break;
      default:
        label = "";
    }
    return (
      <Box
        justify="between"
        alignContent="center"
        direction="row"
        key={charge._id}
      >
        <Text>{charge.name}</Text>
        <Box
          style={{ width: "fit-content", margin: "auto" }}
          pad={{ horizontal: "small" }}
          align="center"
          round="small"
          border={{ color: color, size: "small" }}
        >
          <Text size="12px" color={color}>
            {label}
          </Text>
        </Box>
        {charge.status === "requested" &&
          (charge.reference === "menu" ? (
            <ViewMenuRequestModal
              onDecline={() => handleDeclineCharge(charge)}
              onApprove={() => handleApproveCharge(charge)}
              hotel={hotel}
              chargeId={charge._id}
              status={charge.status}
            />
          ) : (
            <Box direction="row" gap="small">
              <Button
                style={{ marginLeft: 10, fontSize: 14 }}
                label="Decline"
                size="small"
                onClick={() => handleDeclineCharge(charge)}
                primary
                color="status-error"
                alignSelf="center"
              />
              <Button
                style={{ fontSize: 14 }}
                label="Approve"
                size="small"
                onClick={() => handleApproveCharge(charge)}
                primary
                color="status-ok"
                alignSelf="center"
              />
            </Box>
          ))}
      </Box>
    );
  };

  const handleDeclineCharge = (charge) => {
    presentLoading();
    ChargeService.cancel(charge._id).then(
      (response) => {
        dismissLoading();
        presentToastSuccess(
          "Service cancelled. A email has been sent to the guest."
        );
        refreshDetails(bookingDBId);
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );
  };

  const handleApproveCharge = (charge) => {
    presentLoading();
    ChargeService.approve(charge._id).then(
      (response) => {
        dismissLoading();
        presentToastSuccess(
          "Service approved. A email has been sent to the guest."
        );
        refreshDetails(bookingDBId);
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );
  };

  const handleSendMessage = () => {
    setTabIndex(1);
  };

  const hasKeys = useMemo(
    () =>
      (formValues.ekeys || []).some((key) =>
        key.bookings.find((x) => x._id === formValues._id)
      ) ||
      (formValues.passcodes || []).some((key) =>
        key.bookings.find((x) => x._id === formValues._id)
      ),
    [formValues._id, formValues.ekeys, formValues.passcodes]
  );

  const areRoomOrDatesTouched = useMemo(
    () =>
      formValues.roomNumber !== booking.roomNumber ||
      !moment(formValues.checkinDate).isSame(booking.checkinDate) ||
      !moment(formValues.checkoutDate).isSame(booking.checkoutDate) ||
      formValues.checkinTime !==
        moment.tz(booking.checkinDate, hotel.timezone).format("HH:mm") ||
      formValues.checkoutTime !==
        moment.tz(booking.checkoutDate, hotel.timezone).format("HH:mm"),
    [
      formValues.roomNumber,
      formValues.checkinDate,
      formValues.checkoutDate,
      formValues.checkinTime,
      formValues.checkoutTime,
      booking.roomNumber,
      booking.checkinDate,
      booking.checkoutDate,
      hotel.timezone,
    ]
  );

  const allOfflineCodes = useMemo(
    () =>
      (formValues.passcodes || []).every(
        (key) =>
          key.keyboardPwd.length !== 4 ||
          !key.bookings.some((x) => x._id === formValues._id)
      ),
    [formValues.passcodes, formValues._id]
  );

  const isRoomValid = useMemo(
    () =>
      doors.some(
        (door) =>
          (door.lockId || door.validNoLock) && door.name === booking.roomNumber
      ),
    [booking.roomNumber, doors]
  );

  const handleSendInvitation = () => {
    presentLoading();
    GuestService.sendInvitation(formValues._id).then(
      (response) => {
        dismissLoading();
        presentToastSuccess("Invitation sent");
        refreshDetails(bookingDBId);
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );
  };

  const filterHours =
    (type) =>
    ({ value }) => {
      // if hotel is using oracode
      // if type === 'checkin' then only allow hours at default checkin time or default early-checkin time
      // if type === 'checkout' then only allow hours at default checkout time or default late-checkout time
      return (
        !isOracode ||
        (type === "checkin"
          ? [hotel.checkinTime, hotel.earlyCheckinTime].includes(value)
          : [hotel.checkoutTime, hotel.lateCheckoutTime].includes(value))
      );
    };

  const shouldShowBookingName =
    (booking.bookingFirstname || booking.bookingLastname) &&
    (formValues.firstname !== booking.bookingFirstname ||
      formValues.lastname !== booking.bookingLastname);

  return formValues._id ? (
    <Box
      align="center"
      justify="start"
      pad="medium"
      gap="medium"
      flex="grow"
      margin={{ bottom: "300px" }}
    >
      <Header
        align="center"
        direction="row"
        fill="horizontal"
        className="details-header"
      >
        <Button
          label="Back"
          primary
          onClick={handleBack}
          className="back-button"
        />
        <Heading level="2" className="heading">
          Reservation # : {formValues.bookingId}
        </Heading>
      </Header>
      <Tabs
        key="info"
        activeIndex={tabIndex}
        onActive={onActive}
        className="details-tabs"
      >
        <Tab title="Information">
          <Grid
            className="home-page"
            areas={gridAreas[contextSize]}
            columns={[
              ["auto", "600px"],
              ["auto", "400px"],
            ]}
            rows={rows[contextSize]}
            gap="medium"
          >
            <Box
              gridArea="info"
              pad="medium"
              gap="small"
              background={disabled() ? "disabled-background" : "light-1"}
              round
            >
              <Box direction="row" gap="small">
                <FormField label="First Name" alignSelf="end">
                  <TextInput
                    name="firstname"
                    value={formValues.firstname}
                    onChange={handleInputChange}
                  />
                </FormField>
                <FormField label="Last Name" alignSelf="end">
                  <TextInput
                    name="lastname"
                    value={formValues.lastname}
                    onChange={handleInputChange}
                  />
                </FormField>
              </Box>
              {shouldShowBookingName && (
                <Text color="grey" margin={{ top: "-20px" }}>
                  Reservation name: {booking.bookingFirstname}{" "}
                  {booking.bookingLastname}
                </Text>
              )}
              <BookingCalendarInput
                checkinDate={moment.tz(formValues.checkinDate, hotel.timezone)}
                checkoutDate={moment.tz(
                  formValues.checkoutDate,
                  hotel.timezone
                )}
                onChange={handleBookingDatesChange}
                disabled={disabled()}
              />

              <Box direction="row" gap="small">
                <FormField label="Check-in Time">
                  <HourInput
                    name="checkinTime"
                    value={formValues.checkinTime}
                    onChange={handleHourInputChange}
                    disabled={disabled()}
                    timezone={hotel.timezone}
                    filter={filterHours("checkin")}
                  />
                </FormField>
                <FormField label="Check-out Time">
                  <HourInput
                    name="checkoutTime"
                    value={formValues.checkoutTime}
                    onChange={handleHourInputChange}
                    disabled={disabled()}
                    timezone={hotel.timezone}
                    filter={filterHours("checkout")}
                  />
                </FormField>
              </Box>
              <FormField label="Room number">
                <RoomSelect
                  value={formValues.roomNumber}
                  onChange={handleRoomInputChange}
                  disabled={disabled()}
                />
              </FormField>
              <FormField label="Email">
                <TextInput
                  name="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                  placeholder="example@my.com"
                />
              </FormField>
              <Button
                label="Save"
                onClick={() => handleUpdate("info")}
                primary
                alignSelf="center"
                disabled={disabled()}
              />
            </Box>
            <Box
              gridArea="status"
              pad="medium"
              gap="medium"
              background={disabled() ? "disabled-background" : "light-1"}
              round
              align="center"
            >
              {formValues.onlineCheckin &&
              formValues.onlineCheckin.photoSelfie ? (
                <SecuredImage
                  height="250px"
                  filename={formValues.onlineCheckin.photoSelfie}
                />
              ) : (
                <Image
                  height="150px"
                  width="150px"
                  fit="cover"
                  src={placeholderGuest}
                  className="details-guest-photo"
                />
              )}
              <Box>
                <BookingStatus status={formValues.status} />
              </Box>
              <Text alignSelf="center" color="dark-1" weight="bold">
                Phone Number: {formatPhoneNumberIntl(formValues.phone)}
              </Text>
              <Button
                label="Send Message"
                onClick={handleSendMessage}
                primary
                alignSelf="center"
              />
              {!disabled() && (
                <Button
                  label="Check out"
                  onClick={handleCheckout}
                  primary
                  color="status-error"
                  alignSelf="center"
                />
              )}
            </Box>
            <Box
              gridArea="upsell"
              pad="medium"
              gap="small"
              background={disabled() ? "disabled-background" : "light-1"}
              round
            >
              {hotel.onlineCheckinEnabled && (
                <>
                  <Heading level={4} margin={{ bottom: "small", top: "none" }}>
                    Online Check-in
                  </Heading>
                  {!formValues.onlineCheckin ? (
                    <Box gap="small">
                      <CheckBox
                        name="requestPhotoID"
                        checked={formValues.requestPhotoID}
                        onChange={handleCheckboxChange}
                        label="Request Photo ID + Selfie"
                      />
                      <CheckBox
                        name="requestCardImprint"
                        checked={formValues.requestCardImprint}
                        onChange={handleCheckboxChange}
                        label="Request Card Imprint"
                      />
                    </Box>
                  ) : (
                    <VerifyCheckInModal
                      onSubmit={handleSubmitVerification}
                      status={formValues.status.slug}
                      checkinData={formValues.onlineCheckin}
                      bookingId={formValues._id}
                      hotel={hotel}
                    />
                  )}
                </>
              )}
              <Heading level={4} margin="none">
                Upsell
              </Heading>

              {formValues.charges.map((charge) => renderCharge(charge))}

              {!formValues.charges.find(
                (charge) => charge.reference === "earlyCheckin"
              ) && (
                <CheckBox
                  name="offerEarlyCheckin"
                  checked={formValues.offerEarlyCheckin}
                  onChange={handleCheckboxChange}
                  label="Offer Early Check-in"
                />
              )}
              {!formValues.charges.find(
                (charge) => charge.reference === "upgrade"
              ) && (
                <CheckBox
                  name="offerUpgrade"
                  checked={formValues.offerUpgrade}
                  onChange={handleCheckboxChange}
                  label="Offer Upgrade"
                />
              )}
              {!formValues.charges.find(
                (charge) => charge.reference === "lateCheckout"
              ) && (
                <CheckBox
                  name="offerLateCheckout"
                  checked={formValues.offerLateCheckout}
                  onChange={handleCheckboxChange}
                  label="Offer Late Check-out"
                />
              )}
              {!disabled() && (
                <Box direction="row" gap="medium" align="center">
                  <Button
                    label="Update"
                    onClick={() => handleUpdate("upsell")}
                    primary
                    color="brand"
                    alignSelf="center"
                  />
                  {hotel.onlineCheckinEnabled &&
                    formValues.invitationSent &&
                    formValues.status.slug !== "onlinecheckin" &&
                    !hasKeys && (
                      <ButtonWithConfirm
                        label="Restart Check-in"
                        primary
                        alignSelf="center"
                        color="status-warning"
                        onOK={() => handleUpdate("upsell", true)}
                        buttonOKText="Restart Check-in"
                        alertMessage="This will restart the check-in process. The guest will be warned by text/email/notification."
                      />
                    )}
                  {hotel.onlineCheckinEnabled &&
                    formValues.status.slug === "onlinecheckin" &&
                    !hasKeys && (
                      <ButtonWithConfirm
                        label={
                          formValues.invitationSent
                            ? "Resend pre-check-in invite"
                            : "Send pre-check-in invite"
                        }
                        primary
                        alignSelf="center"
                        color="blue-1"
                        onOK={handleSendInvitation}
                        buttonOKText="Send"
                        alertMessage={`This will send the online pre-check-in invitation${
                          formValues.invitationSent ? " again" : ""
                        }.`}
                      />
                    )}
                </Box>
              )}
            </Box>
            <Box
              gridArea="keys"
              pad="medium"
              gap="medium"
              background={disabled() ? "disabled-background" : "#f5efff"}
              round
            >
              <Heading level={4} margin="none">
                {isOracode
                  ? "Send Passcodes"
                  : "Send Mobile Keys and Passcodes"}
              </Heading>
              <Box direction="row" gap="small">
                <Text>Room:</Text>
                {booking.roomNumber ? (
                  <Box
                    border={{
                      size: "small",
                      color: !isRoomValid ? "status-error" : "brand",
                    }}
                    round
                    pad={{ horizontal: "5px" }}
                  >
                    <Text
                      weight="bold"
                      color={!isRoomValid ? "status-error" : null}
                      style={
                        !isRoomValid ? { textDecoration: "line-through" } : {}
                      }
                    >
                      {booking.roomNumber}
                    </Text>
                  </Box>
                ) : (
                  <Text>Not assigned</Text>
                )}
              </Box>
              {accessDoors.filter((x) => x.type === "area")?.length > 0 && (
                <Box direction="row" gap="small">
                  <Text>Common areas:</Text>
                  <Box gap="xsmall">
                    {(accessDoors || [])
                      .filter((x) => x.type === "area")
                      .map((door) => (
                        <Box
                          key={door._id}
                          border={{ size: "small", color: "brand" }}
                          round
                          pad={{ horizontal: "5px" }}
                          width="fit-content"
                        >
                          <Text weight="bold" truncate>
                            {door.name}
                          </Text>
                        </Box>
                      ))}
                  </Box>
                </Box>
              )}
              {booking && (
                <Box direction="row" justify="between" align="center">
                  <Box
                    style={{ width: "fit-content", margin: "auto" }}
                    pad={{ horizontal: "small" }}
                    align="center"
                    round="small"
                    border={{ color: "status-ok", size: "small" }}
                  >
                    <Text size="xsmall" color="status-ok">
                      {moment
                        .tz(booking.checkinDate, hotel.timezone)
                        .format("ll")}
                    </Text>
                    <Text size="xsmall" color="status-ok">
                      {moment
                        .tz(booking.checkinDate, hotel.timezone)
                        .format("LT")}
                    </Text>
                  </Box>
                  <FormNextLink />
                  <Box
                    style={{ width: "fit-content", margin: "auto" }}
                    pad={{ horizontal: "small" }}
                    align="center"
                    round="small"
                    border={{ color: "status-error", size: "small" }}
                  >
                    <Text size="xsmall" color="status-error">
                      {moment
                        .tz(booking.checkoutDate, hotel.timezone)
                        .format("ll")}
                    </Text>
                    <Text size="xsmall" color="status-error">
                      {moment
                        .tz(booking.checkoutDate, hotel.timezone)
                        .format("LT")}
                    </Text>
                  </Box>
                </Box>
              )}
              <Box direction="row" gap="small" alignSelf="center">
                <Button
                  label={!hasKeys || isOracode ? "Send Keys" : "Update Keys"}
                  onClick={() => handleSendKeys()}
                  primary
                  color="brand"
                  disabled={
                    areRoomOrDatesTouched || !booking.roomNumber || !isRoomValid
                  }
                />
                {areRoomOrDatesTouched && (
                  <Tip
                    content={
                      <>
                        <Text>Room number or dates have been changed.</Text>
                        <Text>
                          Please save the changes before sending keys.
                        </Text>
                      </>
                    }
                  >
                    <StatusWarning color="status-warning" />
                  </Tip>
                )}
                {!isRoomValid && !areRoomOrDatesTouched && (
                  <Tip content="Please configure a smart lock for this room.">
                    <StatusWarning color="status-warning" />
                  </Tip>
                )}
              </Box>
              {!isOracode && (
                <ButtonWithConfirm
                  label="Delete All Keys"
                  primary
                  alignSelf="center"
                  color="status-error"
                  disabled={
                    !hasKeys || areRoomOrDatesTouched || allOfflineCodes
                  }
                  onOK={deleteAllKeys}
                  buttonOKText="Delete"
                  alertMessage="This will remove all keys and passcodes for this reservation only. If it is ongoing, a notification will be sent to the guest."
                />
              )}
              <KeysSentReportModal
                report={keysSentReport}
                onClose={closeKeysSentReport}
                isOracode={isOracode}
                onRetryWithOfflineCodes={() => handleSendKeys("retry_offline")}
              />
            </Box>
            {!isOracode && (
              <Box
                gridArea="magicLink"
                pad="medium"
                gap="small"
                background={disabled() ? "disabled-background" : "light-1"}
                round
              >
                <Heading level={4} margin="none">
                  Mobile app magic link
                </Heading>
                {formValues.magicLink &&
                  (!formValues.linkUsed ? (
                    <Box direction="row" gap="medium">
                      {formValues.magicLink}
                      <CopyToClipboard text={formValues.magicLink}>
                        <Button primary label="Copy" />
                      </CopyToClipboard>
                    </Box>
                  ) : (
                    <Text>Link used</Text>
                  ))}
              </Box>
            )}
            {hotel?.usePasscodes && (
              <Box
                gridArea="passcode"
                pad="medium"
                gap="small"
                background={disabled() ? "disabled-background" : "light-1"}
                round
              >
                <AccessList
                  title="Passcodes"
                  keys={formValues.passcodes}
                  bookingId={formValues._id}
                  hotel={hotel}
                  onRefresh={refreshDetails}
                />
              </Box>
            )}
            {!isOracode && (
              <Box
                gridArea="ekeys"
                pad="medium"
                gap="small"
                background={disabled() ? "disabled-background" : "light-1"}
                round
              >
                <AccessList
                  title="Mobile Keys"
                  keys={formValues.ekeys}
                  bookingId={formValues._id}
                  hotel={hotel}
                  onRefresh={refreshDetails}
                  collapsing={hotel.sendMobileKeyQuietly}
                />
              </Box>
            )}
          </Grid>
        </Tab>

        <Tab
          key="conversation"
          title="Conversation"
          className={
            (hotel.notifications || []).filter(
              (x) => x.booking === bookingDBId && x.type === "message"
            ).length > 0 && "has-new-messages"
          }
        >
          <Conversation
            bookingId={bookingDBId}
            guestName={`${formValues.firstname} ${formValues.lastname}`}
            hotel={hotel}
          />
        </Tab>
        <Tab title="Event logs" key="events">
          <EventList bookingId={bookingDBId} timezone={hotel?.timezone} />
        </Tab>
      </Tabs>
    </Box>
  ) : (
    <div></div>
  );
}
