import React, { useEffect, useState } from "react";
import {
  Box,
  Header,
  Button,
  Heading,
  Accordion,
  AccordionPanel,
  Text,
  TextInput,
  FormField,
  TextArea,
} from "grommet";
import { Trash, Edit, Add, Undo, Magic } from "grommet-icons";
import {
  presentToastSuccess,
  presentToastErrorContent,
  presentToastWarning,
} from "@shared/Toast";
import { HotelService, TrainingDataService } from "../../services";
import { presentAlertPrompt } from "@shared/Alert";
import TrainingDataStatus from "@shared/TrainingDataStatus";
import { useFetchHotel } from "./redux/fetchHotel";
import { useSetCurrentUser } from "./redux/setCurrentUser";
import { ButtonWithConfirm } from "@features/shared";
import { dismissLoading, presentLoading } from "@features/shared/Loading";

export default function SettingsTrainingData() {
  const [list, setList] = useState([]);
  const [trainingData, setTrainingData] = useState();
  const [activeIndex, setActiveIndex] = useState();
  const { hotel } = useFetchHotel();
  const { isLoxeAdmin } = useSetCurrentUser();
  const [aiInstructions, setAiInstructions] = useState(
    hotel?.aiInstructions || ""
  );

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = () => {
    TrainingDataService.getTrainingData().then(
      (response) => {
        setList(response.data);
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const handleSave = () => {
    if (!trainingData._id) {
      TrainingDataService.add(trainingData).then(
        async (response) => {
          setList([...list.slice(0, -1), trainingData]);
          handleActivateAccordionPanel();
          presentToastSuccess(response.data?.message || "Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    } else {
      TrainingDataService.update(trainingData).then(
        async (response) => {
          const nextList = list.map((item) => {
            if (item._id === trainingData._id) {
              return { ...trainingData, ...response.data?.update };
            }
            return item;
          });
          setList(nextList);
          handleActivateAccordionPanel();
          presentToastSuccess(response.data?.message || "Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
  };

  const handleAdd = () => {
    const nextTrainingData = { question: "New question", answer: "" };
    setTrainingData(nextTrainingData);
    handleActivateAccordionPanel([list.length]);
    setList([...list, nextTrainingData]);
  };

  const handleRemoveTrainingData = (trainingData, toRemove) => {
    setTimeout(() => handleActivateAccordionPanel(undefined), 1);
    const definitive = !trainingData?._id || !trainingData?.inModelAt;
    if (toRemove) {
      presentAlertPrompt({
        title: definitive ? "Are you sure?" : "Will be marked to be removed",
        message: definitive
          ? "This training data will be definitely deleted since it's not been added to the current model."
          : "This training data will be marked to be removed on the next model update.",
        onOK: () => {
          if (!trainingData?._id) {
            setList(list.filter((item) => item !== trainingData));
          } else {
            removeTrainingData(trainingData._id, true);
          }
        },
        buttonOKText: "Delete",
        buttonOKColor: "status-critical",
      });
    } else if (trainingData?._id) {
      removeTrainingData(trainingData._id, false);
    }
  };

  const removeTrainingData = (trainingDataId, toRemove) => {
    TrainingDataService.deleteTrainingData(trainingDataId, toRemove).then(
      (response) => {
        onRefresh();
        presentToastSuccess("TrainingData successfully removed.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const handleCreateModel = () => {
    if (isLoxeAdmin && hotel.aiEnabled) {
      createModel();
    } else if (hotel.aiEnabled) {
      presentAlertPrompt({
        title: "Request AI model creation",
        message:
          "Please ensure that all necessary training data has been added. After review, you will receive an email confirming that the model has been updated, which may take up to 48 hours. You can still modify the data before the model is created.",
        onOK: () => {
          requestCreateModel();
        },
        buttonOKText: "Request",
        buttonOKColor: "status-warning",
      });
    } else {
      presentToastWarning(
        "Sorry, this feature is not enabled for your account. Please contact your LOXE account manager."
      );
    }
  };

  const requestCreateModel = () => {
    TrainingDataService.requestCreateModel().then(
      (response) => {
        onRefresh();
        presentToastSuccess("Request successful");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const createModel = () => {
    TrainingDataService.createModel().then(
      (response) => {
        onRefresh();
        presentToastSuccess("Creating model...");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  function handleInputChange({ target: { name, value } }) {
    var values = { ...trainingData };
    values[name] = value;
    setTrainingData(values);
  }

  const handleActivateAccordionPanel = (indexes) => {
    if (indexes === undefined) {
      setActiveIndex([]);
      setTrainingData(undefined);
      return;
    }
    const nextIndex = indexes[0];
    setActiveIndex(nextIndex);
    setTrainingData(list[nextIndex]);
  };

  const handleSaveInstructions = () => {
    presentLoading();
    HotelService.updateHotel({ aiInstructions }).then(
      (response) => {
        if (response.data.ok) {
          presentToastSuccess("Instructions Updated");
        } else {
          presentToastSuccess("Nothing to change");
        }
        dismissLoading();
      },
      (error) => {
        presentToastErrorContent(error);
        dismissLoading();
      }
    );
  };

  return (
    <Box gap="medium" justify="start">
      <Heading alignSelf="start" level="2">
        AI Training
      </Heading>
      <Header
        align="center"
        direction="row"
        justify="between"
        gap="medium"
        fill="horizontal"
      >
        {isLoxeAdmin ? (
          <ButtonWithConfirm
            label="Create AI model"
            primary
            alignSelf="center"
            color="status-warning"
            onOK={handleCreateModel}
            buttonOKText="Create AI model"
            alertMessage="This will create the model and make it available for use."
          />
        ) : (
          <Button
            icon={<Magic size="small" />}
            label="Request to create AI model"
            primary
            color="status-warning"
            onClick={handleCreateModel}
          />
        )}
      </Header>
      <Header justify="start" direction="column" gap="small" align="start">
        <Text>Specific Instructions:</Text>
        <TextArea
          value={aiInstructions}
          onChange={({ target: { value } }) => setAiInstructions(value)}
          style={{ height: 100 }}
          maxLength={500}
        />
        <Button
          label="Save"
          primary
          color="status-ok"
          onClick={handleSaveInstructions}
          size="small"
          alignSelf="end"
        />
        <Button
          primary
          icon={<Add size="small" />}
          label="Add Question/Answer"
          onClick={handleAdd}
          size="small"
        />
      </Header>

      <Box align="center" fill="horizontal">
        <Accordion
          width="xlarge"
          activeIndex={activeIndex}
          onActive={handleActivateAccordionPanel}
        >
          {list.map((item, index) => (
            <AccordionPanel
              key={item._id}
              label={
                <Box
                  direction="row"
                  align="center"
                  pad="small"
                  gap="small"
                  alignContent="start"
                  fill
                >
                  <Box width={{ min: "120px" }}>
                    <TrainingDataStatus trainingData={item} />
                  </Box>
                  <Box fill>
                    <Text margin={{ left: "small" }} truncate>
                      {item?.question}
                    </Text>
                  </Box>
                  <Box direction="row" gap="small" width={{ min: "70px" }}>
                    <Button
                      icon={<Edit size="small" />}
                      size="small"
                      primary
                      color="status-warning"
                      onClick={() => handleActivateAccordionPanel(index)}
                    />
                    <Button
                      icon={
                        item?.willBeRemoved ? (
                          <Undo size="small" />
                        ) : (
                          <Trash size="small" />
                        )
                      }
                      size="small"
                      primary
                      color={
                        item?.willBeRemoved ? "accent-1" : "status-critical"
                      }
                      onClick={() =>
                        handleRemoveTrainingData(item, !item?.willBeRemoved)
                      }
                    />
                  </Box>
                </Box>
              }
            >
              <Box pad="small" background="light-3">
                <FormField label="Question" width="100%">
                  <TextInput
                    value={trainingData?.question}
                    name="question"
                    onChange={handleInputChange}
                  />
                </FormField>
                <FormField label="Answer" width="100%">
                  <TextArea
                    fill
                    value={trainingData?.answer}
                    name="answer"
                    onChange={handleInputChange}
                    style={{ width: "100%", height: 200 }}
                  />
                </FormField>
                <Box
                  as="footer"
                  gap="small"
                  direction="row"
                  align="center"
                  justify="end"
                  pad={{ top: "medium", bottom: "small" }}
                  fill="horizontal"
                >
                  <Button
                    label="Cancel"
                    onClick={() => handleActivateAccordionPanel()}
                    color="dark-3"
                  />
                  <Button
                    label="Save"
                    onClick={handleSave}
                    primary
                    color="status-ok"
                  />
                </Box>
              </Box>
            </AccordionPanel>
          ))}
        </Accordion>
      </Box>
    </Box>
  );
}
