import axios from "axios";
import { API_URL, authHeader } from "./api";

const ENDPOINT_URL = API_URL + "message/hotel/";

class CommunicationService {
  getMessages(bookingId, cursorMessageId) {
    return axios.post(
      ENDPOINT_URL + "list/" + bookingId,
      { cursorMessageId },
      { headers: authHeader() }
    );
  }

  sendMessage(bookingId, message) {
    return axios.post(
      ENDPOINT_URL + bookingId,
      { message },
      { headers: authHeader() }
    );
  }

  markAsRead(messageId) {
    return axios.get(ENDPOINT_URL + messageId + "/read", {
      headers: authHeader(),
    });
  }

  getAIMessages(cursorMessageId) {
    return axios.post(
      ENDPOINT_URL + "list/ai",
      { cursorMessageId },
      { headers: authHeader() }
    );
  }

  sendAIMessage(message, testAsGuest) {
    return axios.post(
      ENDPOINT_URL + "ai",
      { message, testAsGuest },
      { headers: authHeader() }
    );
  }
}

export default new CommunicationService();
