import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  CheckBox,
  Select,
  FormField,
  TextArea,
  Tab,
  Tabs,
  Tip,
  Layer,
} from "grommet";
import HotelService from "../../services/hotel.service";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import { useFetchHotel } from "./redux/hooks";
import { PriceInput } from "@shared";
import { CURRENCY_OPTIONS_LIST, getFilteredPropsObject } from "@common/utils";
import { CircleInformation } from "grommet-icons";
import { CustomTabs } from "@features/shared/CustomTabs";

const defaultValues = {
  deposit: 200,
  expressCheckout: true,
  autoSendEnabled: false,
  requestPhotoID: true,
  requestCardImprint: true,
  offerEarlyCheckin: true,
  offerUpgrade: true,
  offerLateCheckout: true,
  currency: "usd",
  onlineCheckinEnabled: false,
  checkinInvitationDisabled: false,
  // hotel options
  useDefaultCheckinText: true,
  checkinTextEn: "",
  checkinTextFr: "",
};

export default function SettingsCheckin() {
  const { hotel } = useFetchHotel();
  const [formValues, setFormValues] = useState(defaultValues);
  const [showCustomCheckinHelpModal, setShowCustomCheckinHelpModal] =
    useState(false);

  useEffect(() => {
    setFormValues({ ...defaultValues, ...hotel });
  }, [hotel]);

  const handleCancel = () => {
    setFormValues({ ...defaultValues, ...hotel });
  };

  function handleInputChange({ target: { name, value } }) {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  function handleCheckboxChange({ target: { name, checked } }) {
    setFormValues({ ...formValues, [name]: checked });
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const request = getFilteredPropsObject(formValues, defaultValues);
    HotelService.updateHotel(request).then(
      (response) => {
        if (response.data.ok) {
          presentToastSuccess("Information Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const renderCheckbox = (label, name, disabled, tipContent) => (
    <Box direction="row" gap="small">
      <CheckBox
        name={name}
        checked={formValues[name]}
        onChange={handleCheckboxChange}
        label={label}
        disabled={disabled}
      />
      {tipContent && (
        <Tip content={tipContent}>
          <CircleInformation />
        </Tip>
      )}
    </Box>
  );

  const renderSelect = (label, name, options) => (
    <FormField label={label} width="medium">
      <Select
        size="medium"
        labelKey="label"
        valueKey={{ key: "value", reduce: true }}
        placeholder="Select"
        value={formValues[name]}
        options={options}
        onChange={({ value: nextValue }) =>
          setFormValues({ ...formValues, [name]: nextValue })
        }
      />
    </FormField>
  );

  const renderCheckinTextArea = (label) => {
    const name = "checkinText";
    const tabs = [
      {
        title: "English",
        key: `${name}En`,
        content: (
          <TextArea
            name={`${name}En`}
            value={formValues[`${name}En`] || ""}
            onChange={handleInputChange}
            style={{
              height: 200,
              ...(formValues.useDefaultCheckinText && { opacity: 0.5 }),
            }}
            disabled={formValues.useDefaultCheckinText}
            onClick={() =>
              setFormValues({ ...formValues, useDefaultCheckinText: false })
            }
          />
        ),
      },
      {
        title: "French",
        key: `${name}Fr`,
        content: (
          <TextArea
            name={`${name}Fr`}
            value={formValues[`${name}Fr`] || ""}
            onChange={handleInputChange}
            style={{
              height: 200,
              ...(formValues.useDefaultCheckinText && { opacity: 0.5 }),
            }}
            disabled={formValues.useDefaultCheckinText}
            onClick={() =>
              setFormValues({ ...formValues, useDefaultCheckinText: false })
            }
          />
        ),
      },
    ];

    const buttonStyle = (key) => {
      return (
        !formValues[key] && {
          color: "red",
        }
      );
    };

    return (
      <>
        {renderCheckbox(
          "Use default text",
          "useDefaultCheckinText",
          false,
          <>
            <p>
              <b>English</b>
              <br />
              Thank you for choosing The [hotelName]. We are looking forward to
              providing you with a memorable experience.
            </p>

            <p>
              <b>Français</b>
              <br />
              Merci d'avoir choisi [hotelName]. Nous avons hâte de vous offrir
              une expérience mémorable.
            </p>
          </>
        )}
        <div style={{ position: "relative" }}>
          {formValues.useDefaultCheckinText && (
            <div
              style={{
                position: "absolute",
                top: 45,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
              }}
              onClick={() =>
                setFormValues({
                  ...formValues,
                  useDefaultCheckinText: false,
                })
              }
            />
          )}
          <CustomTabs tabs={tabs} buttonStyle={buttonStyle} />
        </div>
      </>
    );
  };

  const handleShowCustomCheckinHelpModal = (e) => {
    e.preventDefault();
    setShowCustomCheckinHelpModal(true);
  };

  return (
    <div>
      <Heading level="1">Online Check-in</Heading>

      <Box width="large" gap="medium" background="light-2" round pad="medium">
        <Heading level={3} margin="none">
          Default Values
        </Heading>
        {renderCheckbox(
          "Enable Auto send keys when checking in",
          "autoSendEnabled"
        )}
        {renderCheckbox("Enable Online Check-in", "onlineCheckinEnabled")}
        {renderCheckbox(
          "Disable Check-in Invitation",
          "checkinInvitationDisabled",
          !formValues.onlineCheckinEnabled
        )}
        {renderCheckbox(
          "Request Photo ID + Selfie",
          "requestPhotoID",
          !formValues.onlineCheckinEnabled
        )}
        {renderCheckbox(
          "Request Card Imprint",
          "requestCardImprint",
          !formValues.onlineCheckinEnabled
        )}
        {renderCheckbox("Enable Express Checkout", "expressCheckout")}
        {renderCheckbox("Offer Early Check-in", "offerEarlyCheckin")}
        {renderCheckbox("Offer Upgrade", "offerUpgrade")}
        {renderCheckbox("Offer Late Check-out", "offerLateCheckout")}
        <Box gap="medium" direction="row">
          <FormField label="Pre-authorization Deposit">
            <PriceInput
              name="deposit"
              value={formValues.deposit}
              onChange={handleInputChange}
              disabled={!formValues.onlineCheckinEnabled}
            />
          </FormField>
          {renderSelect("Currency", "currency", CURRENCY_OPTIONS_LIST)}
        </Box>
      </Box>
      <Box
        width="large"
        gap="medium"
        background="light-2"
        round
        pad="medium"
        margin={{ top: "medium" }}
      >
        <Heading level={3} margin="none">
          Custom Check-in Text{" "}
          <a
            href="/#"
            onClick={handleShowCustomCheckinHelpModal}
            style={{ fontSize: "0.6em", paddingLeft: "10px" }}
          >
            Learn more
          </a>
        </Heading>
        <ul>
          <li>
            To make a text <b>bold</b> use: **bold text**.
          </li>
          <li>
            To make a text <i>italic</i> use: _italic text_.
          </li>
          <li>
            To make a text a <a href="/#">link</a> use: [link text](url).
          </li>
        </ul>
        {renderCheckinTextArea("Check-in Invitation Text")}
      </Box>
      <Box direction="row" justify="between" margin={{ top: "medium" }}>
        <Button label="Cancel" onClick={handleCancel} />
        <Button type="submit" label="Update" primary onClick={handleOnSubmit} />
      </Box>
      {showCustomCheckinHelpModal && (
        <Layer
          onEsc={() => setShowCustomCheckinHelpModal(false)}
          onClickOutside={() => setShowCustomCheckinHelpModal(false)}
          responsive={false}
        >
          <Box pad="medium" gap="small" width="large">
            <img
              src="/images/customChekinEmail.jpg"
              style={{ width: "100%", height: "100%" }}
            />
            <Button
              label="Close"
              onClick={() => setShowCustomCheckinHelpModal(false)}
            />
          </Box>
        </Layer>
      )}
    </div>
  );
}
