import React, { useState } from "react";

export const CustomTabs = ({ tabs, buttonStyle }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      {/* Render Tab Headers */}
      <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
        {tabs.map((tab, index) => (
          <button
            key={tab.key}
            onClick={() => setActiveTab(index)}
            style={{
              padding: "8px 12px",
              border: "none",
              background: activeTab === index ? "#007bff" : "#ddd",
              color: activeTab === index ? "white" : "black",
              cursor: "pointer",
              borderRadius: "4px",
              ...(buttonStyle && buttonStyle(tab.key)),
            }}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {/* Render Tab Content (All Tabs Stay Mounted) */}
      <div>
        {tabs.map((tab, index) => (
          <div
            key={tab.key}
            style={{
              display: activeTab === index ? "block" : "none",
            }}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};
