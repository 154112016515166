import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import { Text, Box } from "grommet";

export default function TrainingDataStatus({ trainingData, onClick }) {
  const [color, setColor] = useState("dark-6");
  const [label, setLabel] = useState("");

  useEffect(() => {
    if (trainingData.willBeRemoved) {
      setColor("status-error");
      setLabel("Will be removed");
    } else if (trainingData.inModel) {
      setColor("status-ok");
      setLabel("Active");
    } else if (!trainingData.answer || trainingData.answer.length < 10) {
      setColor("status-error");
      setLabel("Need Answer");
    } else {
      setColor("status-warning");
      setLabel("Pending");
    }
  }, [trainingData]);

  return (
    <Box
      style={{ width: "fit-content", margin: "auto" }}
      pad={{ horizontal: "small" }}
      align="center"
      round="small"
      border={{ color: color, size: "small" }}
      onClick={onClick}
    >
      <Text size="xsmall" color={color}>
        {label}
      </Text>
    </Box>
  );
}
